import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";

import claimsService from "@/services/claims";
import queriesService from "@/services/queries";
export default {
  mixins: [baseViewMixin, currentUserMixin],
  computed: {
    currentDashboard() {
      return this.$store.getters["currentDashboard/currentDashboard"];
    },
  },
  methods: {
    createOrUpdateCurrentDashboard(dashboardData) {
      return this.$store.dispatch(
        "currentDashboard/createOrUpdate",
        dashboardData
      );
    },
    clearCurrentDashboard() {
      return this.$store.dispatch("currentDashboard/clear");
    },
    loadDashboardQuery() {
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcExecutive
      ) {
        return this.loadDDCExecutiveQueriesDashboard();
      }
    },
    loadDashboard() {
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcExecutive
      ) {
        return this.loadDDCExecutiveDashboard();
      }
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcDefender
      ) {
        return this.loadDDCDefenderDashboard();
      }
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.bankAdministrator
      ) {
        return this.loadBankAdministratorDashboard();
      }
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.bankExecutive
      ) {
        return this.loadBankExecutiveDashboard();
      }
    },
    async loadDDCExecutiveDashboard() {
      const resumesResult = await Promise.all([
        claimsService.getResumeByWfStatus(),
        claimsService.getResumeByMacroStatus(),
      ]);

      const resumeByWf = resumesResult[0].data;

      const resumeByMacroStatus = resumesResult[1].data;
      const actualCurrentDashboard = this.currentDashboard;

      //POR REVISAR
      if (
        this.allConstants.dashboard.groups.ddcExecutive.claimsToAdmit
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcExecutive.claimsToAdmit = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive.claimsToAdmit.statuses
        );
      else
        actualCurrentDashboard.ddcExecutive.claimsToAdmit = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcExecutive.claimsToAdmit.statuses
        );

      //Por asignar
      if (
        this.allConstants.dashboard.groups.ddcExecutive.claimsPendingAssignment
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcExecutive.claimsPendingAssignment = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive
            .claimsPendingAssignment.statuses
        );
      else
        actualCurrentDashboard.ddcExecutive.claimsPendingAssignment = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcExecutive
            .claimsPendingAssignment.statuses
        );

      //Pendiente antecedentes adicionales
      if (
        this.allConstants.dashboard.groups.ddcExecutive.claimsSendPapers
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcExecutive.claimsSendPapers = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive.claimsSendPapers
            .statuses
        );
      else
        actualCurrentDashboard.ddcExecutive.claimsSendPapers = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcExecutive.claimsSendPapers
            .statuses
        );

      //Pendiente cliente
      if (
        this.allConstants.dashboard.groups.ddcExecutive.claimsPendingClient
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcExecutive.claimsPendingClient = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive.claimsPendingClient
            .statuses
        );
      else
        actualCurrentDashboard.ddcExecutive.claimsPendingClient = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcExecutive.claimsPendingClient
            .statuses
        );

      //Pendiente banco
      if (
        this.allConstants.dashboard.groups.ddcExecutive.claimsPendingBank
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcExecutive.claimsPendingBank = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive.claimsPendingBank
            .statuses
        );
      else
        actualCurrentDashboard.ddcExecutive.claimsPendingBank = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcExecutive.claimsPendingBank
            .statuses
        );

      //Pendiente defensor
      if (
        this.allConstants.dashboard.groups.ddcExecutive.claimsPendingDefender
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcExecutive.claimsPendingDefender = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive.claimsPendingDefender
            .statuses
        );
      else
        actualCurrentDashboard.ddcExecutive.claimsPendingDefender = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcExecutive.claimsPendingDefender
            .statuses
        );

      //Cerrados
      if (
        this.allConstants.dashboard.groups.ddcExecutive.claimsClosed
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcExecutive.claimsClosed = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive.claimsClosed.statuses
        );
      else
        actualCurrentDashboard.ddcExecutive.claimsClosed = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcExecutive.claimsClosed.statuses
        );

      this.createOrUpdateCurrentDashboard(actualCurrentDashboard);
    },
    async loadDDCExecutiveQueriesDashboard() {
      const resumesResult = await Promise.all([
        queriesService.getResumeByWfStatus(),
      ]);

      const resumeByWf = resumesResult[0].data;

      const actualCurrentDashboard = this.currentDashboard;

      //POR REVISAR
      if (
        this.allConstants.dashboard.groups.ddcExecutive.queriesPending
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcExecutive.queriesPending = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive.queriesPending
            .statuses
        );
      else
        actualCurrentDashboard.ddcExecutive.queriesPending = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive.queriesPending
            .statuses
        );

      //Cerrados
      if (
        this.allConstants.dashboard.groups.ddcExecutive.queriesClosed
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcExecutive.queriesClosed = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive.queriesClosed.statuses
        );
      else
        actualCurrentDashboard.ddcExecutive.queriesClosed = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcExecutive.queriesClosed.statuses
        );

      this.createOrUpdateCurrentDashboard(actualCurrentDashboard);
    },
    async loadDDCDefenderDashboard() {
      const resumesResult = await Promise.all([
        claimsService.getResumeByWfStatus(),
        claimsService.getResumeByMacroStatus(),
      ]);

      const resumeByWf = resumesResult[0].data;

      const resumeByMacroStatus = resumesResult[1].data;
      const actualCurrentDashboard = this.currentDashboard;

      //Asignados
      if (
        this.allConstants.dashboard.groups.ddcDefender.claimsAssigned
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcDefender.claimsAssigned = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcDefender.claimsAssigned.statuses
        );
      else
        actualCurrentDashboard.ddcDefender.claimsAssigned = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcDefender.claimsAssigned.statuses
        );

      //Incompatibles
      if (
        this.allConstants.dashboard.groups.ddcDefender.claimsNotCompatible
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcDefender.claimsNotCompatible = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcDefender.claimsNotCompatible
            .statuses
        );
      else
        actualCurrentDashboard.ddcDefender.claimsNotCompatible = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcDefender.claimsNotCompatible
            .statuses
        );

      //Antecedentes adicionales
      if (
        this.allConstants.dashboard.groups.ddcDefender.claimsNotCompatible
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcDefender.claimsSendPapers = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcDefender.claimsSendPapers
            .statuses
        );
      else
        actualCurrentDashboard.ddcDefender.claimsSendPapers = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcDefender.claimsSendPapers
            .statuses
        );

      //Pendiente cliente
      if (
        this.allConstants.dashboard.groups.ddcDefender.claimsPendingClient
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcDefender.claimsPendingClient = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcDefender.claimsPendingClient
            .statuses
        );
      else
        actualCurrentDashboard.ddcDefender.claimsPendingClient = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcDefender.claimsPendingClient
            .statuses
        );

      //Pendiente banco
      if (
        this.allConstants.dashboard.groups.ddcDefender.claimsPendingBank
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcDefender.claimsPendingBank = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcDefender.claimsPendingBank
            .statuses
        );
      else
        actualCurrentDashboard.ddcDefender.claimsPendingBank = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcDefender.claimsPendingBank
            .statuses
        );

      //Resueltos
      if (
        this.allConstants.dashboard.groups.ddcDefender.claimsClosed
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.ddcDefender.claimsClosed = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.ddcDefender.claimsClosed.statuses
        );
      else
        actualCurrentDashboard.ddcDefender.claimsClosed = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.ddcDefender.claimsClosed.statuses
        );

      this.createOrUpdateCurrentDashboard(actualCurrentDashboard);
    },
    async loadBankAdministratorDashboard() {
      const resumesResult = await Promise.all([
        claimsService.getResumeByWfStatus(),
        claimsService.getResumeByMacroStatus(),
      ]);

      const resumeByWf = resumesResult[0].data;
      const resumeByMacroStatus = resumesResult[1].data;
      const actualCurrentDashboard = this.currentDashboard;

      //Ingresados
      if (
        this.allConstants.dashboard.groups.bankAdministrator.claimsCreated
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankAdministrator.claimsCreated = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankAdministrator.claimsCreated
            .statuses
        );
      else
        actualCurrentDashboard.bankAdministrator.claimsCreated = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankAdministrator.claimsCreated
            .statuses
        );

      //Por responder
      if (
        this.allConstants.dashboard.groups.bankAdministrator.claimsForAnswer
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankAdministrator.claimsForAnswer = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankAdministrator.claimsForAnswer
            .statuses
        );
      else
        actualCurrentDashboard.bankAdministrator.claimsForAnswer = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankAdministrator.claimsForAnswer
            .statuses
        );

      //Prorrogables
      if (
        this.allConstants.dashboard.groups.bankAdministrator.claimsInExtension
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankAdministrator.claimsInExtension = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankAdministrator.claimsInExtension
            .statuses
        );
      else
        actualCurrentDashboard.bankAdministrator.claimsInExtension = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankAdministrator.claimsInExtension
            .statuses
        );

      //Pendiente antecedentes adicionales
      if (
        this.allConstants.dashboard.groups.bankAdministrator.claimsSendPapers
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankAdministrator.claimsSendPapers = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankAdministrator.claimsSendPapers
            .statuses
        );
      else
        actualCurrentDashboard.bankAdministrator.claimsSendPapers = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankAdministrator.claimsSendPapers
            .statuses
        );

      //Pendiente cliente
      if (
        this.allConstants.dashboard.groups.bankAdministrator.claimsPendingClient
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankAdministrator.claimsPendingClient = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankAdministrator
            .claimsPendingClient.statuses
        );
      else
        actualCurrentDashboard.bankAdministrator.claimsPendingClient = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankAdministrator
            .claimsPendingClient.statuses
        );

      //Pendiente Ejecutivo DDC
      if (
        this.allConstants.dashboard.groups.bankAdministrator.claimsPendingDDC
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankAdministrator.claimsPendingDDC = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankAdministrator.claimsPendingDDC
            .statuses
        );
      else
        actualCurrentDashboard.bankAdministrator.claimsPendingDDC = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankAdministrator.claimsPendingDDC
            .statuses
        );

      //Pendiente Defensor DDC
      if (
        this.allConstants.dashboard.groups.bankAdministrator
          .claimsPendingDefender.statusType ==
        this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankAdministrator.claimsPendingDefender = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankAdministrator
            .claimsPendingDefender.statuses
        );
      else
        actualCurrentDashboard.bankAdministrator.claimsPendingDefender = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankAdministrator
            .claimsPendingDefender.statuses
        );

      //Resueltos
      if (
        this.allConstants.dashboard.groups.bankAdministrator.claimsClosed
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankAdministrator.claimsClosed = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankAdministrator.claimsClosed
            .statuses
        );
      else
        actualCurrentDashboard.bankAdministrator.claimsClosed = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankAdministrator.claimsClosed
            .statuses
        );

      this.createOrUpdateCurrentDashboard(actualCurrentDashboard);
    },
    async loadBankExecutiveDashboard() {
      const resumesResult = await Promise.all([
        claimsService.getResumeByWfStatus(),
        claimsService.getResumeByMacroStatus(),
      ]);

      const resumeByWf = resumesResult[0].data;
      const resumeByMacroStatus = resumesResult[1].data;
      const actualCurrentDashboard = this.currentDashboard;

      //Ingresados
      if (
        this.allConstants.dashboard.groups.bankExecutive.claimsCreated
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankExecutive.claimsCreated = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankExecutive.claimsCreated
            .statuses
        );
      else
        actualCurrentDashboard.bankExecutive.claimsCreated = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankExecutive.claimsCreated
            .statuses
        );

      //Por responder
      if (
        this.allConstants.dashboard.groups.bankExecutive.claimsPendingResponse
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankExecutive.claimsPendingResponse = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankExecutive.claimsPendingResponse
            .statuses
        );
      else
        actualCurrentDashboard.bankExecutive.claimsPendingResponse = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankExecutive.claimsPendingResponse
            .statuses
        );

      //Prorrogables
      if (
        this.allConstants.dashboard.groups.bankExecutive.claimsInExtension
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankExecutive.claimsInExtension = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankExecutive.claimsInExtension
            .statuses
        );
      else
        actualCurrentDashboard.bankExecutive.claimsInExtension = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankExecutive.claimsInExtension
            .statuses
        );

      //Pendiente antecedentes adicionales
      if (
        this.allConstants.dashboard.groups.bankExecutive.claimsSendPapers
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankExecutive.claimsSendPapers = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankExecutive.claimsSendPapers
            .statuses
        );
      else
        actualCurrentDashboard.bankExecutive.claimsSendPapers = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankExecutive.claimsSendPapers
            .statuses
        );

      //Pendiente cliente
      if (
        this.allConstants.dashboard.groups.bankExecutive.claimsPendingClient
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankExecutive.claimsPendingClient = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankExecutive.claimsPendingClient
            .statuses
        );
      else
        actualCurrentDashboard.bankExecutive.claimsPendingClient = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankExecutive.claimsPendingClient
            .statuses
        );

      //Pendiente Ejecutivo DDC
      if (
        this.allConstants.dashboard.groups.bankExecutive.claimsPendingDDC
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankExecutive.claimsPendingDDC = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankExecutive.claimsPendingDDC
            .statuses
        );
      else
        actualCurrentDashboard.bankExecutive.claimsPendingDDC = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankExecutive.claimsPendingDDC
            .statuses
        );

      //Pendiente Defensor
      if (
        this.allConstants.dashboard.groups.bankExecutive.claimsPendingDefender
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankExecutive.claimsPendingDefender = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankExecutive.claimsPendingDefender
            .statuses
        );
      else
        actualCurrentDashboard.bankExecutive.claimsPendingDefender = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankExecutive.claimsPendingDefender
            .statuses
        );

      //Resueltos
      if (
        this.allConstants.dashboard.groups.bankExecutive.claimsClosed
          .statusType == this.allConstants.dashboard.statusType.wf
      )
        actualCurrentDashboard.bankExecutive.claimsClosed = this.sumarize(
          resumeByWf,
          this.allConstants.dashboard.groups.bankExecutive.claimsClosed.statuses
        );
      else
        actualCurrentDashboard.bankExecutive.claimsClosed = this.sumarize(
          resumeByMacroStatus,
          this.allConstants.dashboard.groups.bankExecutive.claimsClosed.statuses
        );

      this.createOrUpdateCurrentDashboard(actualCurrentDashboard);
    },
    sumarize(resumeSource, filters) {
      let sum = 0;

      resumeSource.forEach((source) => {
        if (filters.includes(source.code)) {
          sum = sum + source.count;
        }
      });

      return sum;
    },
  },
};
