<template>
  <v-card elevation="1" tile class="pa-5">
    <div class="subtitle-1 font-weight-bold mb-5">CONSULTAS CERRADAS</div>

    <DataTables
      :queries="queries"
      :showDaysLeft="false"
      :showCustomSort="true"
      :loading="page.loading"
      :changePageFilter="true"
      @gotoQuery="gotoQuery"
      @changePage="changePage"
      @search="search"
    />
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";
import currentDashboardMixin from "@/mixins/currentDashboard";

import queriesService from "@/services/queries";
import DataTables from "@/components/WFSite/Queries/DataTables";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
    currentDashboardMixin,
  ],
  components: { DataTables },
  data() {
    return {
      queries: [],
    };
  },
  methods: {
    async gotoQuery(selectedQueryInfo) {
      await this.loadQueryForWf(
        selectedQueryInfo.queryId,
        selectedQueryInfo.userIdentification,
        this.$route.name
      );
      this.gotoView("queryDetailInWf");
    },
    getQuerys(requiredPageNumber, filter) {
      this.initSignalLoading();

      let pageNumber = requiredPageNumber ? requiredPageNumber : 1;
      if (pageNumber > 1) this.loadDashboard();

      const currentPageData = this.currentPage;

      queriesService
        .getByWfStatusesV2(
          this.allConstants.dashboard.groups.ddcExecutive.queriesClosed
            .wfStateType,
          pageNumber,
          15,
          "",
          filter
        )
        .then((getResult) => {
          this.queries = getResult.data.queries;
          currentPageData.totalPages = getResult.data.pagesTotal;
          currentPageData.totalRecords = getResult.data.recordsTotal;

          currentPageData.routerName = this.$route.name;
          currentPageData.pageNumber = pageNumber;

          this.createOrUpdateCurrentPage(currentPageData);
          this.stopSignalLoading();
        })
        .catch(() => {
          this.queries = [];
          currentPageData.totalPages = 0;
          currentPageData.totalRecords = 0;

          currentPageData.routerName = this.$route.name;
          currentPageData.pageNumber = pageNumber;
          this.createOrUpdateCurrentPage(currentPageData);
          this.stopSignalLoading();
        });
    },
    search(filter) {
      this.queries = [];
      this.getQuerys(1, filter);
    },
    changePage(change) {
      this.queries = [];
      this.getQuerys(change.toPageNumber, [], change.sort);
    },
    customSort(sort) {
      let pageNumber = 1;
      if (this.currentPage.routerName == this.$route.name)
        pageNumber = this.currentPage.pageNumber ?? 1;

      this.getQuerys(pageNumber, [], sort);
    },
  },
  beforeMount() {
    let pageNumber = 1;
    if (this.currentPage.routerName == this.$route.name)
      pageNumber = this.currentPage.pageNumber ?? 1;

    this.getQuerys(pageNumber, []);
  },
};
</script>

<style lang="scss" scoped></style>
