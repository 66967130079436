import Vue from "vue";
import axios from "axios";
import axiosRetry from "axios-retry";
import store from "@/store/index";

const apiQueries = axios.create({
  baseURL: `${Vue.prototype.$Settings.all.coreApi.url}/queries`,
  withCredentials: true, //true para soportar cookies de respuests
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
    "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`,
  },
});

const apiEvents = axios.create({
  baseURL: `${Vue.prototype.$Settings.all.coreApi.url}/currentevents`,
  withCredentials: true, //true para soportar cookies de respuests
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
    "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`,
  },
});

axiosRetry(apiQueries, {
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

axiosRetry(apiEvents, {
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

apiQueries.interceptors.request.use((configuration) => {
  const currentUserAccount = Vue.prototype.$Authentication.getAccount();
  const sessionId = Vue.prototype.$Authentication.getSessionId();
  if (currentUserAccount) {
    configuration.headers.CurrentUserToken = currentUserAccount.token;
    if (sessionId) configuration.headers.sessionId = sessionId;
  }

  return configuration;
});

apiEvents.interceptors.request.use((configuration) => {
  const currentUserAccount = Vue.prototype.$Authentication.getAccount();
  const sessionId = Vue.prototype.$Authentication.getSessionId();
  if (currentUserAccount) {
    configuration.headers.CurrentUserToken = currentUserAccount.token;
    if (sessionId) configuration.headers.sessionId = sessionId;
  }
  return configuration;
});

apiQueries.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status == 500) {
      store.dispatch(
        "notifications/addError",
        "Servicio no disponible. Vuelva a intentar"
      );
    }

    if (
      (error.response && error.response.status == 401) ||
      (error.response && error.response.status == 403)
    ) {
      store.dispatch("currentUser/logout");
      store.dispatch("notifications/addInfo", "Su sesión ha expirado");
      store.dispatch("notifications/setMiddleware");
    }
    return Promise.reject(error);
  }
);

apiEvents.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status == 500) {
      store.dispatch(
        "notifications/addError",
        "Servicio no disponible. Vuelva a intentar"
      );
    }

    if (
      (error.response && error.response.status == 401) ||
      (error.response && error.response.status == 403)
    ) {
      store.dispatch("currentUser/logout");
      store.dispatch("notifications/addInfo", "Su sesión ha expirado");
      store.dispatch("notifications/setMiddleware");
    }
    return Promise.reject(error);
  }
);

export default {
  create(queryData, claimCreatorId, claimCreatorRolId) {
    const currentUserAccount = Vue.prototype.$Authentication.getAccount();

    if (currentUserAccount) {
      return apiQueries.post("/", queryData); //el interceptor de axios agrega el encabezado para este caso
    } else {
      const headers = {
        CurrentUserId: claimCreatorId,
        CurrentUserRoleId: claimCreatorRolId,
      };
      return apiQueries.post("/", queryData, { headers: headers });
    }
  },
  GetQueriesByClient() {
    return apiQueries.get(`/GetQueriesByClient`);
  },
  getById(id) {
    return apiQueries.get(`/${id}`);
  },
  getResumeByWfStatus() {
    return apiQueries.get("/resume");
  },
  changeWfStatusFromAction(id, data) {
    return apiQueries.post(`/${id}/actionStates`, data);
  },
  getByWfStatusesV2(wfStateType, pageNumber, pageSize, channel, filter) {
    const expectedPageNumber = pageNumber ? pageNumber : 1;
    const expectedPageSize = pageSize ? pageSize : 10;
    const expectedChannel = channel ?? "";

    return apiQueries.get(
      `/bystate/v2/${wfStateType}?currentPage=${expectedPageNumber}&pageSize=${expectedPageSize}&channel=${expectedChannel}`,
      {
        headers: {
          currentfilters: JSON.stringify(filter),
        },
      }
    );
  },
};
