import Vue from "vue";
import axios from "axios";
import axiosRetry from "axios-retry";
import store from "@/store/index";

const apiClaims = axios.create({
  baseURL: `${Vue.prototype.$Settings.all.coreApi.url}/claims`,
  withCredentials: true, //true para soportar cookies de respuests
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
    "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`,
  },
});

const apiEvents = axios.create({
  baseURL: `${Vue.prototype.$Settings.all.coreApi.url}/currentevents`,
  withCredentials: true, //true para soportar cookies de respuests
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
    "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`,
  },
});

axiosRetry(apiClaims, {
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

axiosRetry(apiEvents, {
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

apiClaims.interceptors.request.use((configuration) => {
  const currentUserAccount = Vue.prototype.$Authentication.getAccount();
  const sessionId = Vue.prototype.$Authentication.getSessionId();
  if (currentUserAccount) {
    configuration.headers.CurrentUserToken = currentUserAccount.token;
    if (sessionId) configuration.headers.sessionId = sessionId;
  }

  return configuration;
});

apiEvents.interceptors.request.use((configuration) => {
  const currentUserAccount = Vue.prototype.$Authentication.getAccount();
  const sessionId = Vue.prototype.$Authentication.getSessionId();
  if (currentUserAccount) {
    configuration.headers.CurrentUserToken = currentUserAccount.token;
    if (sessionId) configuration.headers.sessionId = sessionId;
  }
  return configuration;
});

apiClaims.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status == 500) {
      store.dispatch(
        "notifications/addError",
        "Servicio no disponible. Vuelva a intentar"
      );
    }

    if (
      (error.response && error.response.status == 401) ||
      (error.response && error.response.status == 403)
    ) {
      store.dispatch("currentUser/logout");
      store.dispatch("notifications/addInfo", "Su sesión ha expirado");
      store.dispatch("notifications/setMiddleware");
    }
    return Promise.reject(error);
  }
);

apiEvents.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status == 500) {
      store.dispatch(
        "notifications/addError",
        "Servicio no disponible. Vuelva a intentar"
      );
    }

    if (
      (error.response && error.response.status == 401) ||
      (error.response && error.response.status == 403)
    ) {
      store.dispatch("currentUser/logout");
      store.dispatch("notifications/addInfo", "Su sesión ha expirado");
      store.dispatch("notifications/setMiddleware");
    }
    return Promise.reject(error);
  }
);

export default {
  create(claimData, claimCreatorId, claimCreatorRolId) {
    const currentUserAccount = Vue.prototype.$Authentication.getAccount();

    if (currentUserAccount) {
      return apiClaims.post("/", claimData); //el interceptor de axios agrega el encabezado para este caso
    } else {
      const headers = {
        CurrentUserId: claimCreatorId,
        CurrentUserRoleId: claimCreatorRolId,
      };
      return apiClaims.post("/", claimData, { headers: headers });
    }
  },
  getByIdentification(identification) {
    return apiClaims.get(`/ByRut/${identification}`);
  },
  getById(id) {
    return apiClaims.get(`/${id}`);
  },
  getByWfStatus(wfStatusCode, pageNumber, pageSize) {
    const expectedPageNumber = pageNumber ? pageNumber : 1;
    const expectedPageSize = pageSize ? pageSize : 10;

    return apiEvents.get(
      `/bystate/${wfStatusCode}?currentPage=${expectedPageNumber}&pageSize=${expectedPageSize}`
    );
  },
  getByWfStatuses(wfStatuses, pageNumber, pageSize, channel, filter) {
    const expectedPageNumber = pageNumber ? pageNumber : 1;
    const expectedPageSize = pageSize ? pageSize : 10;
    const expectedChannel = channel ?? "";

    return apiEvents.get(
      `/bystate/${wfStatuses.join(
        ","
      )}?currentPage=${expectedPageNumber}&pageSize=${expectedPageSize}&channel=${expectedChannel}`,
      {
        headers: {
          currentfilters: JSON.stringify(filter),
        },
      }
    );
  },
  getByWfStatusesV2(wfStateType, pageNumber, pageSize, channel, filter) {
    const expectedPageNumber = pageNumber ? pageNumber : 1;
    const expectedPageSize = pageSize ? pageSize : 10;
    const expectedChannel = channel ?? "";

    return apiEvents.get(
      `/bystate/v2/${wfStateType}?currentPage=${expectedPageNumber}&pageSize=${expectedPageSize}&channel=${expectedChannel}`,
      {
        headers: {
          currentfilters: JSON.stringify(filter),
        },
      }
    );
  },
  getByMacroStatus(macroStatusCode, pageNumber, pageSize, filter, order) {
    const expectedPageNumber = pageNumber ? pageNumber : 1;
    const expectedPageSize = pageSize ? pageSize : 10;

    return apiClaims.get(
      `/bystates/${macroStatusCode}?currentPage=${expectedPageNumber}&pageSize=${expectedPageSize}`,
      {
        headers: {
          currentfilters: JSON.stringify(filter),
          currentorder: JSON.stringify(order),
        },
      }
    );
  },
  getCurrentWfStatus(id) {
    return apiClaims.get(`/${id}/currentEvent`);
  },
  changeWfStatusFromAction(id, currentStatus, actionCode, data) {
    return apiClaims.post(
      `/${id}/actionStates/${currentStatus}/${actionCode}`,
      data
    );
  },
  changeWfStatusFromActionPersonalized(id, currentStatus, actionCode, data) {
    return apiClaims.post(
      `/${id}/ActionPersonalized/${currentStatus}/${actionCode}`,
      data
    );
  },
  addDocuments(claimId, data) {
    return apiClaims.post(`/${claimId}/documents`, data);
  },
  changeDocumentStatus(claimId, documentId, state, data) {
    return apiClaims.post(`/${claimId}/documents/${documentId}/${state}`, data);
  },
  changeDocumentInformation(claimId, documentId, data) {
    return apiClaims.post(`/${claimId}/documents/${documentId}`, data);
  },
  getResumeByWfStatus() {
    return apiEvents.get("/resume");
  },
  getResumeByMacroStatus() {
    return apiClaims.get("/resume");
  },
};
