import allConstantsMixin from "@/mixins/allConstants";

import queriesService from "@/services/queries";
import accountsService from "@/services/accounts";

export default {
  mixins: [allConstantsMixin],
  computed: {
    currentQuerySelected() {
      return this.$store.getters["currentQuerySelected/currentQuery"];
    },
  },
  methods: {
    async loadQuery(queryId) {
      const getResults = await Promise.allSettled([
        queriesService.getById(queryId),

        //accountsService.getByIdentification(userIdentification),
      ]);

      let realQuery = getResults[0].value.data;
      if (realQuery.query.userIdDDC == "00000000-0000-0000-0000-000000000000") {
        realQuery.lastHumanEvent = null;
      } else {
        let lastHumanEvent = { userName: "" };
        const getByIdResult = await accountsService.getById(
          realQuery.query.userIdDDC
        );
        const lastHumanEventAccount = getByIdResult.data.account;

        lastHumanEvent.userName = `${lastHumanEventAccount.name} ${lastHumanEventAccount.surname}`;

        realQuery.lastHumanEvent = lastHumanEvent;
      }
      return this.$store.dispatch(
        "currentQuerySelected/createOrUpdate",
        realQuery
      );
    },
    reloadQuery() {
      if (this.currentQuerySelected?.currentFilter) {
        const currentFilterWfStatus = this.currentQuerySelected.currentFilter
          .requiredWfStatus;
        const currentFilterClaimStatus = this.currentQuerySelected.currentFilter
          .requiredMacroStatus;

        return this.loadQuery(
          this.currentQuerySelected.query.id,
          this.currentQuerySelected.account.identificationNumber,
          currentFilterWfStatus,
          currentFilterClaimStatus
        );
      }
      return this.loadQuery(
        this.currentQuerySelected.query.id,
        this.currentQuerySelected.account.identificationNumber
      );
    },
    async loadQueryForWf(queryId, userIdentification, fromRouteName) {
      const getResults = await Promise.allSettled([
        queriesService.getById(queryId),
        //accountsService.getByIdentification(userIdentification),
      ]);

      let realQuery = getResults[0].value.data;

      realQuery.fromRouteName = fromRouteName;
      if (realQuery.query.userIdDDC == "00000000-0000-0000-0000-000000000000") {
        realQuery.lastHumanEvent = null;
      } else {
        let lastHumanEvent = { userName: "" };
        const getByIdResult = await accountsService.getById(
          realQuery.query.userIdDDC
        );
        const lastHumanEventAccount = getByIdResult.data.account;

        lastHumanEvent.userName = `${lastHumanEventAccount.name} ${lastHumanEventAccount.surname}`;

        realQuery.lastHumanEvent = lastHumanEvent;
      }

      //get the last event in wich a user has participated

      //persist selected claim
      return this.$store.dispatch(
        "currentQuerySelected/createOrUpdate",
        realQuery
      );
    },
    reloadQueryForWf() {
      this.loadQueryForWf(
        this.currentQuerySelected.query.id,
        this.currentQuerySelected.account.identificationNumber,
        this.currentQuerySelected.fromRouteName
      );
    },
    unloadQuery() {
      return this.$store.dispatch("currentQuerySelected/clear");
    },
    checkIfActionCanBeExecuted(actionCode) {
      const result = {
        canBe: false,
        errorCode: "",
        errorMessage: "",
      };
      if (actionCode == this.allConstants.queries.posibleActions.admitDDC) {
        result.canBe = true;
      }
      return result;
    },

    generateValues(posibleValues) {
      let result = [];

      if (posibleValues != undefined && posibleValues.length > 0) {
        posibleValues.forEach((v) => {
          result.push({
            name: v.name,
            value: v.input,
          });
        });
      }

      return result;
    },
    changeWfStatusFromAction(typeResponseCode, comments) {
      const queryId = this.currentQuerySelected.query.id;

      const actionContent = {
        typeResponseCode: typeResponseCode ?? "",
        comments: comments ?? "",
      };

      return queriesService.changeWfStatusFromAction(queryId, actionContent);
    },
    getEvent(forActionCode) {
      return this.currentQuerySelected.query.events.find(
        (event) => event.actionCode == forActionCode
      );
    },
    async getEventAndUser(forActionCode) {
      let resolutionEvent = this.currentQuerySelected.query.events.find(
        (event) => event.actionCode == forActionCode
      );
      if (!resolutionEvent) return;

      const userAccountGetByIdResult = await accountsService.getById(
        resolutionEvent.userId
      );

      resolutionEvent.userName = `${userAccountGetByIdResult.data.account.name} ${userAccountGetByIdResult.data.account.surname}`;

      return resolutionEvent;
    },
  },
};
