<template>
  <div>
    <v-form v-model="form.isValid" @submit.prevent="search">
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="folioSearch"
            label="N° Consulta"
            placeholder="Ingrese un N° Consulta"
            outlined
            dense
            required
            :rules="folioRules"
            :disabled="form.processing || loading"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="rutSearch"
            label="Rut"
            placeholder="Ingrese rut del consultante"
            outlined
            dense
            required
            :rules="rutRules"
            :disabled="form.processing || loading"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            class="mx-2"
            fab
            small
            dark
            color="blue lighten-3"
            :disabled="!form.isValid || form.processing"
            :loading="loading"
            @click="eraser"
          >
            <v-icon>
              mdi-eraser
            </v-icon>
          </v-btn>
          <v-btn
            type="submit"
            class="mx-2"
            fab
            small
            color="primary"
            :disabled="!form.isValid || form.processing"
            :loading="loading"
          >
            <v-icon dark>
              mdi-magnify
            </v-icon>
          </v-btn>
          <ImportExcel
            v-if="importExcel"
            :disabled="!form.isValid || form.processing"
            :loading="loading"
            :data="dataExportExcel"
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="text-right"
          v-if="queries.length > 0 && !loading"
        >
          <v-spacer /> Cantidad de consultas {{ $n(currentPage.totalRecords) }}
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="computedHeaders"
          :items="queries"
          :loading="loading"
          loading-text="Cargando consultas"
          :no-data-text="noDataText"
          :items-per-page="itemsPerPage"
          hide-default-footer
          dense
          @update:options="custom"
          :sort-by="computedSortKey"
          :sort-desc="orderType"
        >
          <template v-slot:[`item.folio`]="{ item }">
            <v-progress-linear
              buffer-value="0"
              stream
              color="cyan"
              v-if="form.processing || loading"
            ></v-progress-linear>
            <router-link
              to="#"
              v-on:click.native="gotoQuery(item.id, item.rut)"
              v-else
              >{{ item.folio }}</router-link
            >
          </template>
          <template v-slot:[`item.rut`]="{ item }">
            <div class="one-line">{{ item.rut | formatRut }}</div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div v-if="item.name.length <= 25">{{ item.name }}</div>
            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="one-line" v-bind="attrs" v-on="on"
                    >{{ item.name.substring(0, 25) }}...</span
                  >
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.userIdDDC`]="{ item }">
            <div v-if="getNameDefender(item.userIdDDC).length <= 17">
              {{ getNameDefender(item.userIdDDC) }}
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="one-line" v-bind="attrs" v-on="on"
                    >{{
                      getNameDefender(item.userIdDDC).substring(0, 17)
                    }}...</span
                  >
                </template>
                <span>{{ getNameDefender(item.userIdDDC) }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.finnancialInstitute`]="{ item }">
            {{ getBankName(item.finnancialInstitute) }}
          </template>
          <template v-slot:[`item.state`]="{ item }">
            {{ item.state == "PENDIENTE" ? "Ingresada" : "Respondida" }}
          </template>
          <template v-slot:[`item.creationDate`]="{ item }">
            <span class="one-line" v-if="item.creationDate">{{
              item.creationDate | formatDateShort
            }}</span>
            <span class="one-line" v-else>{{
              item.startDate | formatDateShort
            }}</span>
          </template>
          <template v-slot:[`item.plazo`]="{ item }" v-if="showDaysLeft">
            <div class="one-line" v-if="item.howMuchLeft != undefined">
              <v-icon v-if="item.flag == 'Red'" color="red"
                >mdi-clock-time-four-outline</v-icon
              >
              <v-icon v-if="item.flag == 'Green'" color="green"
                >mdi-clock-time-four-outline</v-icon
              >
              <v-icon v-if="item.flag == 'Yellow'" color="yellow"
                >mdi-clock-time-four-outline</v-icon
              >
              {{ item.howMuchLeft.value | formatDaysFromHours }}
            </div>
          </template>
        </v-data-table>

        <v-divider></v-divider>
        <v-pagination
          :disabled="loading || form.processing"
          v-model="currentPage.pageNumber"
          :length="currentPage.totalPages"
          :total-visible="10"
          @input="changePage"
          v-if="queries.length > 0 && currentPage.totalPages > 1 && !loading"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import wfStatusFiltersMixin from "@/filters/claimWfStatusFilters";

import ImportExcel from "@/components/ImportExcel";

import sxRut from "sx-rut";

import authenticationConstants from "@/plugins/authenticationConstants";

export default {
  mixins: [baseViewMixin, parametersMixin, currentUserMixin],
  components: {
    ImportExcel,
  },
  props: {
    queries: {
      type: Array,
    },
    showDaysLeft: {
      type: Boolean,
      default: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "No hay consultas",
    },
    showCustomSort: {
      type: Boolean,
      default: false,
    },

    sortKey: {
      type: String,
      default: "",
    },
    orderType: {
      type: Boolean,
      default: false,
    },
    importExcel: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    changePageFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedState() {
      let result = this.states.map((state) => {
        return { value: state.code, text: state.name };
      });

      const indexOne = result.findIndex((key) => key.value === "PENDIENTE");
      result.splice(indexOne, 1);

      return result;
    },
    computedHeaders() {
      if (!this.currentUserIsAuthenticated) return this.headersHideDaysLeft;

      const userRole = this.currentUserInfo.roles[0];
      if (userRole != authenticationConstants.userRolesCodes.ddcExecutive) {
        if (this.showDaysLeft)
          return this.headers.filter((h) => h.value != "previousQueries");

        if (this.showNameDefender)
          return this.headersNameDefender.filter(
            (h) => h.value != "previousQueries"
          );

        return this.headersHideDaysLeft.filter(
          (h) => h.value != "previousQueries"
        );
      } else {
        if (this.showDaysLeft) return this.headers;
        if (this.showNameDefender) return this.headersNameDefender;
      }

      return this.headersHideDaysLeft;
    },
    computedSortKey() {
      switch (this.sortKey) {
        case "f":
          return "folio";
        case "r":
          return "rut";
        default:
          return "";
      }
    },
    computedFilter() {
      return [
        { key: "lsc", value: this.estadoSearch },
        { key: "if", value: this.folioSearch },
        { key: "r", value: this.rutSearch },
      ];
    },

    dataExportExcel() {
      let result = [];
      this.claims.forEach((c) => {
        c.bankName = this.getBankName(c.bankCode);
        c.defenderName = this.getNameDefender(c.userIdDDC);

        c.state = wfStatusFiltersMixin.wfStatusFromAction(c.lastActionCode);
        if (c.lastUpdateDate == "0001-01-01T00:00:00Z") c.lastUpdateDate = "";

        //arreglar fechas

        result.push(c);
      });
      return result;
    },
  },
  data() {
    return {
      folioSearch: "",
      rutSearch: "",
      modal: false,
      headers: [
        { text: "N° Consulta", value: "folio", search: true },
        { text: "Rut", value: "rut", search: true },
        { text: "Cliente", value: "name", search: true },
        { text: "Banco", value: "finnancialInstitute" },
        { text: "Estado", value: "state" },
        { text: "Fecha Creación", value: "creationDate" },
        { text: "Plazo", value: "plazo", sortable: false },
      ],
      headersHideDaysLeft: [
        { text: "N° Consulta", value: "folio", search: true },
        { text: "Rut", value: "rut", search: true },
        { text: "Cliente", value: "name", search: true },
        { text: "Banco", value: "finnancialInstitute" },
        { text: "Estado", value: "state" },
        { text: "Fecha Creación", value: "creationDate" },
      ],
      headersNameDefender: [
        { text: "N° Consulta", value: "folio", search: true },
        { text: "Rut", value: "rut", search: true },
        { text: "Cliente", value: "name", search: true },
        { text: "Defensor", value: "userIdDDC", search: true },
        { text: "Banco", value: "finnancialInstitute" },
        { text: "Estado", value: "state" },
        { text: "Fecha Creación", value: "creationDate" },
      ],

      sort: {
        key: "f",
        orderType: 1,
      },
      rutRules: [
        (value) =>
          !value ||
          this.allConstants.regex.regexRut.test(value) ||
          "No es válido",
        (value) => !value || sxRut.isValidRut(value) || "ingrese un rut válido",
      ],
      folioRules: [
        (value) =>
          !value ||
          this.allConstants.regex.regexDescription.test(value) ||
          "No es válido",
      ],
    };
  },
  methods: {
    custom(options) {
      if (!this.showCustomSort) return;
      if (options.sortBy.length == 0 && options.sortDesc.length == 0) return;

      return;
    },
    search() {
      this.$emit("search", this.computedFilter);
    },
    gotoQuery(queryId, userIdentification) {
      this.initSignalProcessing();
      this.$emit("gotoQuery", {
        queryId: queryId,
        userIdentification: userIdentification,
      });
    },
    eraser() {
      this.folioSearch = "";
      this.rutSearch = "";
    },
    changePage(toPageNumber) {
      if (this.changePageFilter)
        this.$emit("changePage", {
          toPageNumber: toPageNumber,
          filter: this.computedFilter,
          sort: this.sort,
        });
      else this.$emit("changePage", toPageNumber);
    },
  },
  beforeMount() {},
};
</script>

<style lang="scss" scoped>
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
</style>
