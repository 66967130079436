<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        fab
        small
        color="success"
        :disabled="disabled || data.length == 0"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark>
          mdi-microsoft-excel
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Exportación de archivo Excel
      </v-card-title>
      <v-card-text
        >Cantidad de registros a exportar {{ data.length }}</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>

        <download-excel
          :data="data"
          :fields="json_fieldsComputed"
          :name="fileName"
          :before-generate="beforeGenerate"
          :before-finish="beforeFinish"
        >
          <v-btn dark color="green darken-1">Descargar Excel</v-btn>
        </download-excel>
        <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
          :disabled="donwloadLoading"
        >
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "reclamos-",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    json_fields: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      donwloadLoading: false,
      json_fieldsClaims: {
        Folio: "internalFolio",
        Rut: "rut",
        Cliente: "name",
        Banco: "bankName",
        Defensor: "defenderName",
        Estado: "state",
        "Fecha Creación": "creationDate",
        "Fecha último cambio estado": "lastUpdateDate",
        "Última Acción": "currentEvent.stateName",
        Tipología: "TypologyName",
      },
    };
  },
  methods: {
    beforeGenerate() {
      this.donwloadLoading = true;
    },
    beforeFinish() {
      this.donwloadLoading = false;
      this.dialog = false;
    },
  },
  computed: {
    fileName() {
      const today = new Date();
      return `${this.name} ${today.toISOString().substring(0, 13)}`;
    },
    json_fieldsComputed() {
      if (this.json_fields == undefined || this.json_fields == null)
        return this.json_fieldsClaims;

      return this.json_fields;
    },
  },
};
</script>

<style lang="scss" scoped></style>
