import accountsService from "@/services/accounts";

export default {
  data() {
    return {
      defenders: [],
    };
  },
  methods: {
    getTypologyName(code) {
      const typology = this.$Parameters.all.find(
        (parameter) => parameter.type == "Typology"
      ).values;
      return typology.find((role) => role.code == code)?.name ?? code;
    },
    getRoleName(code) {
      const roles = this.$Parameters.all.find(
        (parameter) => parameter.type == "UserRoles"
      ).values;
      return roles.find((role) => role.code == code)?.name ?? code;
    },
    getBankName(code) {
      const banks = this.$Parameters.all.find(
        (parameter) => parameter.type == "BankCodes"
      ).values;
      console.log("bancos..1",this.banks)
      console.log("bancos..code",code)
      return banks.find((bank) => bank.code == code)?.name ?? code;
    },
    getProductName(code) {
      const products = this.$Parameters.all.find(
        (parameter) => parameter.type == "ProductCodes"
      ).values;
      return products.find((product) => product.code == code)?.name ?? code;
    },
    getCurrencyName(code) {
      const currencies = this.$Parameters.all.find(
        (parameter) => parameter.type == "CurrencyTypes"
      ).values;
      return currencies.find((currency) => currency.code == code)?.name ?? code;
    },
    getClaimMacroStatusName(code) {
      const statuses = this.$Parameters.all.find(
        (parameter) => parameter.type == "ClaimStates"
      ).values;
      return statuses.find((status) => status.code == code)?.name ?? code;
    },
    getClaimWfStatusName(code) {
      const statuses = this.$Parameters.all.find(
        (parameter) => parameter.type == "WfStatuses"
      ).values;
      return statuses.find((status) => status.code == code)?.name ?? code;
    },
    getChannelName(code) {
      const channels = this.$Parameters.all.find(
        (parameter) => parameter.type == "Channels"
      ).values;
      return channels.find((channel) => channel.code == code)?.name ?? code;
    },
    getReasonName(code) {
      const reasons = this.$Parameters.all.find(
        (parameter) => parameter.type == "ReasonCodes"
      ).values;
      return reasons.find((reason) => reason.code == code)?.name ?? code;
    },
    getDocumentStatusName(code) {
      const documentStatuses = this.$Parameters.all.find(
        (parameter) => parameter.type == "DocumentStates"
      ).values;
      return (
        documentStatuses.find((status) => status.code == code)?.name ?? code
      );
    },
    getRegionName(code) {
      const regions = this.$Parameters.all.find(
        (parameter) => parameter.type == "Regions"
      ).values;
      return regions.find((region) => region.code == code)?.name ?? code;
    },
    getProvinceName(regionCode, provinceCode) {
      const regions = this.$Parameters.all.find(
        (parameter) => parameter.type == "Regions"
      ).values;
      const region = regions.find((region) => region.code == regionCode);

      const province = region.provinces.find(
        (province) => province.code == provinceCode
      );
      return province.name;
    },
    getCommuneName(regionCode, provinceCode, communeCode) {
      let findedCommune;

      const regions = this.$Parameters.all.find(
        (parameter) => parameter.type == "Regions"
      ).values;
      const region = regions.find((region) => region.code == regionCode);

      if (provinceCode) {
        const province = region.provinces.find(
          (province) => province.code == provinceCode
        );
        province.communes.forEach((commune) => {
          if (commune.code == communeCode) {
            findedCommune = commune;
          }
        });
      }

      if (!provinceCode) {
        region.provinces.forEach((province) => {
          province.communes.forEach((commune) => {
            if (commune.code == communeCode) {
              findedCommune = commune;
            }
          });
        });
      }

      return findedCommune?.name ?? communeCode;
    },
    getMonth(value) {
      switch (value) {
        case "1":
          return "Enero";
        case "2":
          return "Febrero";
        case "3":
          return "Marzo";
        case "4":
          return "Abril";
        case "5":
          return "Mayo";
        case "6":
          return "Junio";
        case "7":
          return "Julio";
        case "8":
          return "Agosto";
        case "9":
          return "Septiembre";
        case "10":
          return "Octubre";
        case "11":
          return "Noviembre";
        case "12":
          return "Diciembre";
      }

      return value;
    },
    loadDefenders() {
      accountsService
        .getByRole(this.allConstants.security.userRolesCodes.ddcDefender)
        .then((result) => {
          this.defenders = result.data.accounts;
        })
        .catch(() => {
          this.defenders = [];
        });
    },
    getNameDefender(id, nameDefault) {
      const defender = this.defenders.find((defender) => defender.id == id);

      if (defender != undefined || defender != null) return defender.name;

      if (defender == undefined || defender == null) return "";
      if (nameDefault == undefined || defaultStatus == null) return "";

      return nameDefault;
    },
  },
};
